<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
           <SideMenu :indexsec="6"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  name_form,
                  parent_form
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR CATEGORIA</p>
              </div>

              <div class="div_EPForm">
              <div class="row mx-0 row-two">
                <div class="EPF1_C1">
                  <label class="labelN " for="inputN">Nombre</label>
                  <b-form-input
                  
                    v-model="name_form"
                    required
                    pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    id="inputN"
                    type="text"
                  />
                </div>
                 <div class="">
                    <label class="labelR " for="inputR">Categoría padre</label>
                    <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{parent_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.categories" :key="key">
                          <b-dropdown-item @click="changeCategory(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
              
              </div>

             <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>

              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal">La categoría se agrego correctamente</p>
                  </div>
                  <div class="">
                    
                     
                     <div class="btns-dflx">
                      <div class="btn-void"></div>
                    <b-button class="btn-modal" @click="editarOtro()"
                      >AÑADIR OTRO</b-button
                    >
                      <b-button class="btn-modal" @click="toEdit()"
                        >EDITAR</b-button
                      >
                      
                    </div>
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import Header from '../../components/Header';

import { mapActions } from "vuex";
export default {
  name: "AddUser",
   components: {
    Header,
    SideMenu,
  },
  
  data() {
    return {
      name_form:"",
      status: "",
      msg: "",
      parent_form: 1,
      parent_name: 'NINGUNA',
      added:'',
      IconSuccess: IconSuccess,
      
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
     this.getCategories('Categorías')
  },
   computed: {
     user() {            
            return this.$store.getters['admin/getIdentity'];
        },
     categories() {
       let allctg =this.$store.getters["categories/data"];
       let result =[]
       let model;
       for(var i =0; i<allctg.length; i++){
          if(allctg[i].id== 1){
             model ={
                        id: allctg[i].id,
                        id_parent: allctg[i].id_parent,
                        name: 'NINGUNA',
                        parent_name: allctg[i].parent_name
                      }
          }else{
            model ={
            id: allctg[i].id,
            id_parent: allctg[i].id_parent,
            name: allctg[i].name,
            parent_name: allctg[i].parent_name
            }
          }
          result.push(model)
       }

      return result
    },
  },
  methods: {
     ...mapActions('categories', ['getCategories']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    toEdit: function () {
       this.$router.push("/editar/categoria/"+this.added.id).catch((err) => {});
    },
    submit: async function (name, id_parent) {
      let category = {
        name: name,
        id_parent:String(id_parent) 
      };
      
      
        this.status=''
          this.msg=''

          let result = await   this.$store.dispatch("main/addItem",  {option:'category', item: category});
    
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
          this.added =result.added
              this.showModal() 
          }
  
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
    changeCategory: function (value, name) {
      this.parent_form = value;
      this.parent_name = name;
    
      
    },
  },
};
</script>





